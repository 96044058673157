$(".toggle-menu").click(function(){
	if($('.toggle-menu').hasClass('open')) {
		$(this).removeClass('open');
		$('.page-wrap').removeClass('open');
		$('#offcanvas').removeClass('open');
    $('#nav').css('display', 'none')
	} else {
		$('.menu-text').css({content: "Back"});
		$(this).addClass('open');
		$('.page-wrap').addClass('open');
		$('#offcanvas').addClass('open');
    $('#nav').css('display', 'block')
	}
});

function loadOffCanVasIfNotExist() {
	if($("#offcanvas").find(".inner").length <= 0) {
		$.get("/partial/offcanvas", function(data) {
			$("#offcanvas").append(data)
		});
	}
}

function addAdditionalSlide() {
	if(window.matchMedia("(min-width: 768px)").matches) {
		//load additional slides
		$.get("/partial/additional_slide", function(data) {
			//create jquery object of loaded html chunk
			//then 'add' each slide section into owl-carousel object
			var slides = $(data);
			slides.siblings().each(function(index){
				owl.owlCarousel("add", $(this)[0].outerHTML);
			});
		});
		owl.owlCarousel("refresh");
		$('.owl-nav').css("display", "block");

	} else {
		// console.log("probably a mobile device");
		owl.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
    owl.find('.owl-stage-outer').children().unwrap();
	}
}

/*
var owl = $('.owl-carousel');
// according http://stackoverflow.com/questions/25769366/owl-carousel-2-current-item-when-page-loads
owl.on('initialize.owl.carousel', function(e) {
});
owl.on('initialized.owl.carousel change.owl.carousel changed.owl.carousel', function(e) { 
    if (!e.namespace || e.type != 'initialized' && e.property.name != 'position') return;
    
    var current = e.relatedTarget.current();
    var items = $(this).find('.owl-stage').children();
    var add = e.type == 'changed' || e.type == 'initialized';
    
    var current_item = items.eq(e.relatedTarget.normalize(current));
    current_item.find('.title-contents').css("display", "block");
    current_item.find('.title-header').toggleClass('fadeInDown', add);
    current_item.find('.title-sub').toggleClass('fadeInUp', add);
});

owl.owlCarousel({
    loop: true,
    margin:0,
    nav: true,
    autoplay: true,
    lazyload: true,
    responsive:{
      0:{
          items:1
      },
    },
    navText: [
    	'<i class="fa fa-chevron-left fa-2x"></i>',
    	'<i class="fa fa-chevron-right fa-2x"></i>'
    ],
});
// addAdditionalSlide();

$(".gallery").magnificPopup({
	delegate: 'a',
	type: 'image',
	gallery: {
		enabled: true
	}
});
*/

if(window.matchMedia("(min-width: 1200px)").matches) {
	// set about alfa laval as a long text
	$(".alfalaval").append(
		'<div class="content-body"><p>Alfa Laval is a leading global provider of specialized products and engineering solutions based on three key technologies; heat transfer, separation and fluid handling.</p><p>The company’s equipment systems and services are dedicated to helping customers optimize the performance of their processes. The solutions help them to heat, cool, separate and transport products such as oil, water, chemicals, beverages, foodstuffs, starch and pharmaceuticals.</p> <p>Alfa Laval ’s worldwide organization works closely with customers in 100 countries to help them stay ahead in the global arena. <a href="http://www.alfalaval.com">www.alfalaval.com</a></p></div>'
	)
} else {
	// set about alfa laval as a short text
	$(".alfalaval").append(
		'<div class="content-body"><p>Alfa Laval is a leading global provider of products and solutions based on heat transfer,separation and fluid handling. The company helps customers to optimize the performance of their processes-and to heat, cool, separate and transport products in a wide range of industries.</p><p>Alfa Lavals worldwide organization works closely with customers in 100 countries. <a href="http://www.alfalaval.com">www.alfalaval.com</a></p></div>'
	)
}

$(".readmore").click(function(){
	$(this).parent().next().toggleClass("show-p");
});

$(".alfalaval .content-body p:first-child").click(function(){
	$(this).toggleClass("hide-readmore");
	$(this).nextAll().toggleClass("show");
})

window.onresize = function(){
	loadOffCanVasIfNotExist();

  if(document.body.clientWidth > 1200) {
    // make sure the .main-nav is appear
    $('#nav').css('display', 'inline-block')
  } else {
    // make sure the .main-nav is hide
    $('#nav').css('display', 'none')
    document.querySelector('.toggle-menu').classList.remove('open')
  }
}
window.onresize();
